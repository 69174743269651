<ion-content [fullscreen]="true" class="ion-align-items-center">
    <p class="header">
        <img src="../../../assets/images/mygtnportal-logo.svg" alt="MyGTNPortal logo" width="175"/>
    </p>
    <div class="content" *ngIf="loginStep === loginStepInit" (keyup.enter)="onLoginClicked()">
        <div class="login-box ion-margin">
            <ion-item>
                <ion-label position="floating">User ID</ion-label>
                <ion-input autocomplete="username" [(ngModel)]="userName" type="email"></ion-input>
            </ion-item>
            <ion-item>
                <ion-icon class="ion-padding-top-sm ion-margin-top" size="small" color="dark" slot="end" name="{{showPassword ? 'eye-off': 'eye'}}" (click)="showPassword = !showPassword"></ion-icon>
                <ion-label position="floating">Password</ion-label>
                <ion-input [(ngModel)]="userPassword" type="{{showPassword ? 'text': 'password'}}"></ion-input>
            </ion-item>
        </div>
        <ion-button class="ion-margin"
                    [disabled]="isLoggingIn || (!userName || !userPassword)"
                    (click)="onLoginClicked()"
                    expand="block">
            {{isLoggingIn ? 'Processing . . .' : 'Log In'}}
        </ion-button>
        <ion-label class="ion-float-left ion-no-padding ion-padding-start smaller">
            <a href="javascript:void(0)" (click)="onForgotPasswordClicked()">Forgot Password?</a>
        </ion-label>
        <ion-label class="ion-float-right ion-no-padding ion-padding-end smaller">
            <a href="javascript:void(0)" (click)="onForgotUserIdClicked()">Forgot User ID?</a>
        </ion-label>
    </div>
    <div class="content bg-box" *ngIf="loginStep === loginStep2fa" (keyup.enter)="onVerifyClicked()">
        <ion-item lines="none" class="bold bigger">
            Two-Factor Authentication
        </ion-item>
        <ion-item lines="none" class="ion-no-margin">
            <ion-label class="ion-text-wrap">
                An email message with a 4-digit verification code was sent to email ID {{maskedEmail}}
            </ion-label>
        </ion-item>
        <ion-item lines="inset">
            <ion-label position="stacked">Enter Two-Factor Authentication Code</ion-label>
            <ion-input type="number" inputmode="numeric" [(ngModel)]="tfaCode"></ion-input>
        </ion-item>
        <ion-item lines="none">
            <ion-label>Remember this device?</ion-label>
            <ion-toggle slot="end" [(ngModel)]="rememberMe"></ion-toggle>
        </ion-item>
        <div>
            <ion-button class="ion-padding-horizontal ion-float-right"
                        [disabled]="isLoggingIn || tfaCode?.toString()?.length != 4"
                        (click)="onVerifyClicked()">Verify
            </ion-button>
            <ion-button color="secondary" class="ion-padding-horizontal ion-float-left" (click)="onCancelClicked()">
                Cancel
            </ion-button>
        </div>
    </div>
    <div class="content bg-box" *ngIf="loginStep === loginStepLockedOut">
        <div class="account-locked-box">
            <ion-item lines="none" class="bold bigger">Account Locked</ion-item>
            <ion-item lines="none" class="invalid-login">Irregular activity has been detected</ion-item>
            <ion-item lines="none">
                Your account has been temporarily locked because you have reached the maximum number of invalid login
                attempts.
            </ion-item>
            <ion-item class="ion-margin-top" lines="none">
                You will be able to regain access to your account in a maximum of 30 minutes.
            </ion-item>
            <ion-item lines="none">For assistance, contact:</ion-item>
            <ion-item lines="none"><a href="mailto:help@mygtnportal.com">help@mygtnportal.com</a></ion-item>
            <ion-button class="ion-margin-top ion-padding-horizontal" expand="block" (click)="resetLogin()">Back to Login
            </ion-button>
        </div>
    </div>
    <div class="content bg-box" *ngIf="loginStep === loginStepForgotPw || loginStep === loginStepForgotUserId"
         (keyup.enter)="onForgotSubmitClicked()">
        <ion-item class="bold bigger" lines="none">
            {{loginStep === loginStepForgotPw ? 'Reset Password' : 'Forgot User ID'}}
        </ion-item>
        <ion-item lines="none" class="ion-no-margin">
            <ion-label class="ion-text-wrap">
                Enter your {{loginStep === loginStepForgotPw ? 'user ID' : 'email address'}} below to recover
                your {{loginStep == loginStepForgotPw ? 'user password' : 'user ID'}}. For assistance, contact
                <a href="mailto:help@mygtnportal.com">help@mygtnportal.com</a>
            </ion-label>
        </ion-item>
        <ion-item lines="inset">
            <ion-label position="stacked">{{loginStep === loginStepForgotPw ? 'User ID' : 'Email Address'}}</ion-label>
            <ion-input [(ngModel)]="forgotId"></ion-input>
        </ion-item>
        <div class="ion-margin-vertical ion-padding-horizontal">
            <ion-button class="ion-float-right" [disabled]="!forgotId || isLoggingIn"
                        (click)="onForgotSubmitClicked()">Submit
            </ion-button>
            <ion-button class="ion-float-left" color="secondary" (click)="onCancelClicked()">Cancel</ion-button>
        </div>
    </div>
</ion-content>
<ion-footer class="ion-no-border" *ngIf="showFooter && loginStep === loginStepInit">
    <table class="footer-table">
        <tr>
            <td>
                <a href="https://www.gtn.com/privacy-policies">Privacy Policy</a>
            </td>
            <td>
                <a href="https://www.gtn.com/">gtn.com</a>
            </td>
            <td>
                <a href="https://www.gtn.com/terms-and-conditions">Terms of Use</a>
            </td>
        </tr>
    </table>
</ion-footer>

