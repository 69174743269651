import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

export class FormUtilities {
    static getFormErrorMessagesHtml(formGroup: FormGroup): string {
        const messages = [];

        if (formGroup.errors) {
            Object.keys(formGroup.errors).forEach(keyError => {
                if (!messages.find(m => m === formGroup[keyError])) {
                    messages.push(formGroup.errors[keyError]);
                }
            });
        }

        Object.keys(formGroup.controls).forEach(key => {
            // TODO USE RECURSION FOR FORM GROUPS IN FORM GROUPS?
            const controlErrors: ValidationErrors = formGroup.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    if (!messages.find(m => m === controlErrors[keyError])) {
                        messages.push(controlErrors[keyError]);
                    }
                });
            }
        });

        return messages.join('<br/>');
    }

    static getRequiredValidator(message: string = 'Missing required field'): (control: AbstractControl) => any {
        return (control: AbstractControl) => {
            if (!control.value) {
                return {required : message};
            }

            return null;
        };
    }
}
