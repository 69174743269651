<ion-card class="ion-no-margin">
    <ion-card-header color="secondary">
        <ion-card-title>

            Password Requirements
            <ion-icon (click)="onCloseClicked()" class="ion-float-right" name="close" color="primary"></ion-icon>
        </ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <p class="ion-padding-top">A strong password should have at least 8 characters and contain at least 1 of each of the
            following:</p>
        <ul>
            <li>Lowercase letters</li>
            <li>Uppercase letters</li>
            <li>Numbers</li>
            <li>Special characters (e.g., ! @ # $ %)</li>
        </ul>
        <p>Passwords cannot contain <em>just</em> the following:</p>
        <ul>
            <li>Only consecutive numbers or letters (e.g., 1234 or abcdef)</li>
            <li>The user's name (e.g., &lt;first name&gt;, &lt;last name&gt;, etc.)</li>
            <li>Any spelling of the word “password” (e.g., Password, pAsSwOrD)</li>
            <li>Repeating characters or repeating character sets (e.g., 1111111111, 111222333444)</li>
        </ul>
    </ion-card-content>
</ion-card>
