import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({providedIn: 'root'})
export class ToasterService {
    constructor(private toastController: ToastController) {
        this.toastController.create();
    }

    private readonly defaultMsg = 'An unexpected error occurred.';

    showSuccess(message: string): void {
        this.toastController.create({
            message,
            duration: 5000,
            position: 'top',
            color: 'success',
            buttons: [
                {
                    icon: 'close-circle',
                    role: 'cancel',
                    handler: () => {
                        // console.log('Cancel clicked');
                    }
                }
            ]
        }).then(instance => {
            instance.present();
        });
    }

    showError(message: string = this.defaultMsg): void {
        this.toastController.create({
            message: message || this.defaultMsg,
            duration: 5000,
            position: 'top',
            color: 'danger',
            buttons: [
                {
                    icon: 'close-circle',
                    role: 'cancel',
                    handler: () => {
                        // console.log('Cancel clicked');
                    }
                }
            ]
        }).then(instance => {
            instance.present();
        });
    }

    showWarning(message: string = this.defaultMsg): void {
        this.toastController.create({
            message: message || this.defaultMsg,
            duration: 5000,
            position: 'top',
            color: 'warning',
            buttons: [
                {
                    icon: 'close-circle',
                    role: 'cancel',
                    handler: () => {
                        // console.log('Cancel clicked');
                    }
                }
            ]
        }).then(instance => {
            instance.present();
        });
    }
}
