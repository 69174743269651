/* tslint:disable:no-string-literal */
import { Injectable } from '@angular/core';

import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({providedIn: 'root'})
export class LoggingService {

    public errors: Error[] = [];

    private appInsights: ApplicationInsights;
    private aiEnabled = false;

    constructor(private authService: AuthService) {
    }

    init() {
        if (environment().appInsightsConfig?.instrumentationKey) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment().appInsightsConfig.instrumentationKey,
                    enableAutoRouteTracking: true
                }
            });

            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView();
            this.aiEnabled = true;

            this.appInsights.addTelemetryInitializer((telemetryItem: ITelemetryItem) => {
                // add custom log properties across all Telemetry types here
                telemetryItem.data['ScreenDimensions'] = `${window.screen.width}x${window.screen.height}`;
                telemetryItem.data['ScreenWidth'] = window.screen.width;
                telemetryItem.data['ScreenHeight'] = window.screen.height;
                return true;
            });

            this.authService.userChanged.subscribe((user: any) => {
                if (user) {
                    this.appInsights.setAuthenticatedUserContext(user.id, null, true);
                }
            });
        }
    }

    logInfo(message: string, location: string = '') {
        if (this.aiEnabled) {
            this.appInsights.trackTrace({ message }, { location });
        }
    }

    logError(error: Error) {
        if (this.aiEnabled) {
            this.appInsights.trackException({
                exception: error
            });
        }

        this.errors.push(error);
        console.error(error);
    }
}
