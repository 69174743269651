<ion-content>
    <div class="main-content no-menu-offset vertical-center-items">
        <p class="header">
            <img src="../../../assets/images/mygtnportal-logo.svg" alt="MyGTNPortal logo" width="175"/>
        </p>
        <ion-item lines="none">
            <ion-text class="ion-text-center">
                At this time, the mobile version of My GTN Portal has been specifically designed solely for the taxpayer experience.<br/><br/>
                Please use <a href="{{myGtnPortalUrl}}">https://mygtnportal.com</a> from your computer to access your Portal account.<br/><br/>
                If you believe you've reached this message in error, please contact <a href="mailto:help@mygtnportal.com">help@mygtnportal.com</a> for assistance.
            </ion-text>
        </ion-item>
        <div class="fixed-button-container ion-padding-horizontal ion-margin-vertical">
            <ion-button color="primary" expand="block" (click)="onBackToLoginClicked()">Back to Login
            </ion-button>
        </div>
    </div>
</ion-content>
