import { Component, OnInit } from '@angular/core';
import { PopoverService } from 'src/app/core/services/popover.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-user-password-info',
    templateUrl: './user-password-info.component.html',
    styleUrls: ['./user-password-info.component.scss'],
})
export class UserPasswordInfoComponent implements OnInit {
    constructor(private popoverService: PopoverService) {
    }

    ngOnInit() {
    }

    onCloseClicked(): void {
        this.popoverService.hide().pipe(first()).subscribe();
    }
}
