<ion-card class="ion-no-margin ion-margin-vertical">
    <ion-card-header color="secondary">
        <ion-card-title>
            <div class="ion-float-left">
                {{isSetMode ? 'Set' : 'Change'}} Password
            </div>
            <div class="ion-float-right">
                <ion-icon (click)="onPasswordInfoClicked()" slot="end" name="information-circle"></ion-icon>
            </div>
            <div class="ion-clear-both"></div>
        </ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <form [formGroup]="passwordFormGroup" *ngIf="passwordFormGroup">
            <ion-item class="ion-no-margin ion-no-padding" *ngIf="!isSetMode">
                <ion-label position="stacked" class="required">Old Password</ion-label>
                <ion-input type="{{showOldPassword ? 'text': 'password'}}" formControlName="oldPassword"></ion-input>
                <ion-icon class="password-icon" size="small" color="dark" slot="end" name="{{showOldPassword ? 'eye-off': 'eye'}}" (click)="showOldPassword = !showOldPassword"></ion-icon>
            </ion-item>
            <ion-item class="ion-no-margin ion-no-padding">
                <ion-label position="stacked" class="required">New Password</ion-label>
                <ion-input formControlName="password" type="{{showNewPassword ? 'text': 'password'}}"></ion-input>
                <ion-icon class="password-icon" size="small" color="dark" slot="end" name="{{showNewPassword ? 'eye-off': 'eye'}}" (click)="showNewPassword = !showNewPassword"></ion-icon>
            </ion-item>
            <ion-item class="ion-no-margin ion-no-padding ion-margin-bottom">
                <ion-label position="stacked" class="required">Confirm New Password</ion-label>
                <ion-input formControlName="confirmPassword" type="{{showConfirmPassword ? 'text': 'password'}}"></ion-input>
                <ion-icon class="password-icon" size="small" color="dark" slot="end" name="{{showConfirmPassword ? 'eye-off': 'eye'}}" (click)="showConfirmPassword = !showConfirmPassword"></ion-icon>
            </ion-item>
            <ion-button expand="block"
                        (click)="onPasswordChangeSavedClicked()"
                        [disabled]="isSavingPassword || !passwordFormGroup.dirty">
                {{isSetMode ? 'Set' : 'Change'}} Password
            </ion-button>
        </form>
    </ion-card-content>
</ion-card>
