import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { from, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PopoverService {
    constructor(private popoverController: PopoverController) {

    }

    show(component: any): Observable<void> {
        return from(this.popoverController.create({
            component,
            translucent: true
        }).then(instance => {
            instance.present();
        }));
    }

    hide(data: any = null): Observable<any> {
        return from(this.popoverController.dismiss(data));
    }
}
