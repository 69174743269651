import { ApplicationRef, Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from 'src/app/core/services/auth.service';
import { AppService } from 'src/app/core/services/app.service';
import { routes } from 'src/app/app-routes';
import { Router } from '@angular/router';
import { GpsService } from 'src/app/core/services/gps.service';
import { GpsSettingTypesEnum } from 'src/app/shared/enums/gps-setting-types.enum';
import { SwUpdate } from '@angular/service-worker';
import { ConfirmDialogService } from 'src/app/core/services/confirm-dialog.service';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { isServiceWorkerEnabled } from 'src/app/core/utilities/service-worker.utilties';
import { ConnectivityService } from 'src/app/core/services/connectivity.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { concat, interval } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    user: any;
    menuItems: any[] = [];

    isLoadingLocation = true;
    isLocationDisabled = false;
    locationCountry: string;
    locationCityState: string;
    locationData = null;
    deferredPrompt: any;
    version = null;

    private logOutMenuItem = {
        title: 'Log Out',
        url: routes.login.path,
        icon: 'person-circle'
    };

    private userSettingsMenuItem = {
        title: 'User Settings',
        url: routes.user.children.settings.path,
        icon: 'settings-sharp'
    };

    constructor(
        public appService: AppService,
        public authService: AuthService,
        private platform: Platform,
        private toasterService: ToasterService,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private gpsService: GpsService,
        private applicationRef: ApplicationRef,
        private swUpdate: SwUpdate,
        private confirmDialogService: ConfirmDialogService,
        private connectivityService: ConnectivityService
    ) {
        this.initializeApp();
        this.initServiceWorker();

        // ADD TO HOME SCREEN PROMPT
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
        });
    }

    ngOnInit(): void {
        this.version = environment().version;

        if (window.location.href.indexOf('?version=') > -1) {
            this.confirmDialogService.show('Update Completed', `Version has been updated to ${this.version}`, 'Ok', null, true);
        }


        this.connectivityService.connectivityChanged.subscribe(isOnline => {
            if (!isOnline) {
                this.toasterService.showError('No internet connectivity.');
            } else {
                this.toasterService.showSuccess('Internet connectivity restored.');
            }
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            this.authService.userChanged.subscribe(user => {
                if (user) {
                    this.setGPS();
                    this.setMenuItems(user);
                }
            });

            this.appService.gpsSettingChanged.subscribe(_ => {
                if (this.authService.user) {
                    this.setGPS();
                }
            });
        });
    }

    onAddToHomeScreenClicked(): void {
        if (this.deferredPrompt) {
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice
                .then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the prompt');
                    } else {
                        console.log('User dismissed the prompt');
                    }
                    this.deferredPrompt = null;
                });
        }
    }

    onMenuItemClicked(menuItem: any): void {
        // THIS IS DUE TO IONIC CACHING PAGES, A RELOAD WILL DO A FULL BOUNCE
        if (menuItem === this.logOutMenuItem) {
            this.authService.logout();
            window.location.href = `${window.location.origin}/#${this.logOutMenuItem.url}`;
            location.reload();
            return;
        }

        this.router.navigate([menuItem.url]);
    }

    navigateToUserSettings(): void {
        this.onMenuItemClicked(this.userSettingsMenuItem);
    }

    private setMenuItems(user: any): void {
        this.menuItems = [
            {
                title: 'Dashboard',
                url: routes.dashboard.path,
                icon: 'home'
            },
            {
                title: 'Travel Calendar',
                url: routes.calendar.path,
                icon: 'calendar'
            },
            {
                title: 'Documents',
                url: routes.documents.path,
                icon: 'document'
            },
            {
                title: 'Organizer',
                url: routes.organizer.path,
                icon: 'file-tray-stacked'
            }];

        if (user.userTypes.indexOf(1) !== -1) { // INDEPENDENT
            this.menuItems.push({
                title: 'Payments',
                url: routes.payments.path,
                icon: 'logo-usd'
            });
        }

        this.menuItems = this.menuItems.concat([
            {
                title: 'Questionnaire',
                url: routes.questionnaire.path,
                icon: 'document-text'
            },
            this.userSettingsMenuItem,
            this.logOutMenuItem
        ]);
    }

    private setGPS(): void {
        this.gpsService.getSettings().subscribe((gpsSetting: GpsSettingTypesEnum) => {
            if (gpsSetting === GpsSettingTypesEnum.TrackPeriodically || gpsSetting === GpsSettingTypesEnum.TrackWhenAppOpen) {
                this.gpsService.getCurrentLocation().subscribe(data => {
                    this.locationData = data;
                    this.isLoadingLocation = false;

                    if (data) {
                        this.locationCountry = data.name;
                        this.locationCityState = data?.relatedLocations[0]?.name;

                        if (data?.relatedLocations?.length === 2) {
                            this.locationCityState += ', ' + data?.relatedLocations[1]?.name;
                        }

                        this.gpsService.saveCurrentGpsLocation().subscribe();
                    }
                }, () => {
                    console.warn('GTN:  GPS is not enabled.');
                    this.isLoadingLocation = false;
                    this.isLocationDisabled = true;
                });
            } else {
                this.locationData = null;
                this.isLoadingLocation = false;
            }
        });
    }

    private initServiceWorker(): void {
        if (isServiceWorkerEnabled() && this.swUpdate.isEnabled) {
            const appIsStableCheck = this.applicationRef.isStable.pipe(first(isStable => isStable === true));
            const checkInterval = interval(900000); // EVERY 15 MINUTES
            const appStableCheck = concat(appIsStableCheck, checkInterval);

            appStableCheck.subscribe(() => {
                this.swUpdate.checkForUpdate();
            });

            this.swUpdate.available.subscribe(evt => {
                this.confirmDialogService.show('Update Available', `A new version is available.`, 'Update', null, true)
                    .pipe(first()).subscribe(_ => {
                    this.swUpdate.activateUpdate().then(() => {
                        window.location.href = `${window.location.href}?version=${encodeURIComponent(this.version)}`;
                        document.location.reload();
                    });
                });
            });
        }
    }
}
