import { ErrorHandler, Injectable } from '@angular/core';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { LoggingService } from 'src/app/core/services/logging.service';

@Injectable({providedIn: 'root'})
export class GlobalErrorHandlerService implements ErrorHandler {
    private defaultErrorMsg = 'An unknown error has occurred.  If the problem persists, please try to restart the application.';

    constructor(private toasterService: ToasterService,
                private loggingService: LoggingService) {
    }

    handleError(error, disableHandling = false) {
        if (!disableHandling) {
            if (error?.toString().indexOf('Error: ExpressionChangedAfterItHasBeenCheckedError') > -1) {
                console.warn(error);
                disableHandling = true;
            } else if (typeof error === 'string') {
                if (error.startsWith('[')) {
                    const parsedError = JSON.parse(error);
                    if (parsedError.length && parsedError[0].message) {
                        error = parsedError[0].message;
                    }
                }

                this.toasterService.showError(error || this.defaultErrorMsg);
            } else {
                this.toasterService.showError(this.defaultErrorMsg);
            }

            if (!disableHandling) {
                this.loggingService.logError(error);
                throw error;
            }
        }
    }
}
