import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AlertsService {
    constructor(private http: HttpClient) {

    }

    list(): Observable<any> {
        const url = `${environment().portalApiUrl}/alert/portalBannerAlerts?count=25`;
        return this.http.get<any>(url).pipe(map(data => {
            const regex = /(<([^>]+)>)/ig;

            for (const model of data.models) {
                model.message = decodeURI(model.message.replace(regex, ''));
            }

            return data;
        }));
    }
}
