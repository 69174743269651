import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { routes } from 'src/app/app-routes';
import { AppService } from 'src/app/core/services/app.service';
import { KeyboardService } from 'src/app/core/services/keyboard.service';
import { BasePage } from 'src/app/core/components/base.page';
import { LoadingOverlayService } from 'src/app/core/services/loading-overlay.service';

@Component({
    selector: 'app-login-page',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage extends BasePage {
    userName: string;
    userPassword: string;
    rememberMe = true;
    showRememberMe = false;
    tfaCode: string;
    loginStep = 1;
    tfaCodeErrorCount = 0;
    isLoggingIn = false;
    maskedEmail: string = null;
    forgotId: null;
    showFooter = true;
    showPassword = false;

    readonly loginStepInit = 1;
    readonly loginStep2fa = 2;
    readonly loginStepLockedOut = 3;
    readonly loginStepForgotPw = 4;
    readonly loginStepForgotUserId = 5;

    constructor(private router: Router,
                private keyboardService: KeyboardService,
                private authService: AuthService,
                private appService: AppService,
                private toasterService: ToasterService,
                private loadingOverlayService: LoadingOverlayService) {
        super();
    }

    ionViewWillEnter(): void {
        this.appService.hideMenu = true;
        this.resetLogin();

        this.subscriptions.push(this.authService.loginFailed.subscribe(err => {
            if (err) {
                this.handleLoginFailure(err);
            }
        }));

        this.subscriptions.push(this.authService.isLoggingIn.subscribe(isLoggingIn => {
            this.isLoggingIn = isLoggingIn;
        }));

        this.subscriptions.push(this.keyboardService.keyboardVisibilityChanged.subscribe(isShown => {
            this.showFooter = !isShown;
        }));

        if (this.authService.getUserId() && this.authService.getAuthToken()) {
            this.redirectToApp();
        }
    }

    ionViewWillLeave(): void {
        this.appService.hideMenu = false;
        super.ionViewWillLeave();
    }

    onLoginClicked(): void {
        if (this.isLoggingIn || !this.userName || !this.userPassword) {
            return;
        }

        this.isLoggingIn = true;
        this.authService.login(this.userName, this.userPassword, this.rememberMe, (this.tfaCode ? +this.tfaCode : null)).subscribe(
            (data: { requires2fa: boolean, showRememberMe: boolean, maskedEmail: string }) => {
                if (data.requires2fa) {
                    this.loginStep = this.loginStep2fa;
                    this.showRememberMe = data.showRememberMe;
                    this.isLoggingIn = false;
                    this.maskedEmail = data.maskedEmail;
                } else {
                    this.userName = null;
                    this.userPassword = null;
                    // this.isLoggingIn = false;
                    // this.loginStep = this.loginStepInit;

                    this.redirectToApp();
                }
            }, (err) => {
                this.handleLoginFailure(err);
            });
    }

    onVerifyClicked(): void {
        if (!this.tfaCode) {
            return;
        }

        this.onLoginClicked();
    }

    onCancelClicked(): void {
        this.resetLogin();
    }

    onForgotUserIdClicked(): void {
        this.loginStep = this.loginStepForgotUserId;
    }

    onForgotPasswordClicked(): void {
        this.loginStep = this.loginStepForgotPw;
    }

    onForgotSubmitClicked(): void {
        if (!this.forgotId) {
            return;
        }

        this.isLoggingIn = true;
        this.loadingOverlayService.show();

        if (this.loginStep === this.loginStepForgotPw) {
            this.authService.sendForgotPasswordLink(this.forgotId).subscribe(result => {
                if (result.result) {
                    this.toasterService.showSuccess('Instructions for resetting your password have been sent to your primary email address.');
                    this.resetLogin();
                    this.isLoggingIn = false;
                } else {
                    this.toasterService.showError();
                    this.isLoggingIn = false;
                }

                this.loadingOverlayService.hide();
            }, () => {
                this.toasterService.showError('The user ID was not found.');
                this.isLoggingIn = false;
                this.loadingOverlayService.hide();
            });
        } else {
            this.authService.sendForgotUserIDLink(this.forgotId).subscribe(result => {
                if (result.result) {
                    this.toasterService.showSuccess('If your email is in our system, you will receive an email with instructions on recovering your User ID.');
                    this.resetLogin();
                    this.isLoggingIn = false;
                } else {
                    this.toasterService.showError();
                    this.isLoggingIn = false;
                }

                this.loadingOverlayService.hide();
            }, () => {
                this.toasterService.showError('The user ID was not found.');
                this.isLoggingIn = false;
                this.loadingOverlayService.hide();
            });
        }
    }

    resetLogin(): void {
        this.loginStep = this.loginStepInit;
        this.tfaCode = null;
        this.userPassword = null;
        this.userName = null;
        this.rememberMe = true;
        this.forgotId = null;
        this.tfaCodeErrorCount = 0;
        this.isLoggingIn = false;
    }

    private redirectToApp(): void {
        if (!this.authService.user.isAULASigned) {
            this.router.navigate([routes.documents.children.agreements.path]).then(_ => {
                this.authService.isLoggingIn.next(false);
                this.resetLogin();
            });
        } else {
            this.router.navigate([routes.dashboard.path]).then(_ => {
                this.authService.isLoggingIn.next(false);
                this.resetLogin();
            });
        }
    }

    private handleLoginFailure(err: any) {
        let errorMsg: string = null;

        if (err?.errors || err?.errorMessage || err?.length) {
            let error: string;
            if (err.length) {
                error = err[0].message;
            } else {
                error = err.errors.length > 0 ? err.errors[0].message : err.errorMessage;
            }

            if (error) {
                if (error.includes('Invalid login') || error.includes('Could not find user')) {
                    errorMsg = 'Invalid User ID and/or Password';
                    this.userName = null;
                    this.userPassword = null;
                } else if (error.includes('locked out')) {
                    this.loginStep = this.loginStepLockedOut;
                    this.userName = null;
                    this.userPassword = null;
                } else if (error.includes('Password expired')) {
                    errorMsg = `Login Failed: Your password has expired.  Please log into My GTN Portal then try again.  You will be automatically redirected.`;
                    this.loginStep = this.loginStepForgotPw;
                    this.userName = null;
                    this.userPassword = null;
                } else if (error.includes('Two-Factor')) {
                    this.tfaCodeErrorCount++;
                    if (this.tfaCodeErrorCount > 3) {
                        this.resetLogin();
                    }
                    this.tfaCode = null;
                    errorMsg = error;
                } else {
                    error = null;
                }
            }
        }

        this.toasterService.showError(errorMsg || 'Login Failed: Please try again. If the problem persists, please <a href="${environment.myGtnPortalUrl}/request-assistance" target="_blank">request assistance</a>.');
        this.isLoggingIn = false;
    }
}

