<ion-content>
    <div class="header">
        <ion-item slot="start" lines="none" class="bold">
            <span>
                Notifications
            </span>
        </ion-item>
        <ion-item slot="end" lines="none" (click)="onCloseClicked()">
            <ion-icon name="close" color="primary"></ion-icon>
        </ion-item>
    </div>
    <ion-list class="ion-no-padding">
        <ion-item *ngFor="let alert of alerts">
            <ion-label class="notification ion-text-wrap">
                <div [innerHTML]="alert.message"></div>
            </ion-label>
            <ion-note class="smaller" slot="end">{{alert.postedDate | notificationDate }}</ion-note>
        </ion-item>
    </ion-list>
    <div class="fixed-button-container" *ngIf="isLoaded">
        <ion-button color="primary" expand="block" (click)="onCloseClicked()">Close</ion-button>
    </div>
</ion-content>
