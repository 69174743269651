export const routes = {
    login: {
        path: '/login'
    },
    registration: {
        path: '/registration'
    },
    unsupportedUser: {
        path: '/unsupported-user'
    },
    user: {
        path: '/user',
        children: {
            settings: {
                path: '/user/settings'
            }
        }
    },
    dashboard: {
        path: '/dashboard'
    },
    calendar: {
        path: '/calendar',
        children: {
            trip: {
                create: {
                    path: '/calendar/trip'
                },
                edit: {
                    path: '/calendar/trip/:id'
                }

            },
            conflict: {
                path: '/calendar/conflict/trip/:id'
            },
            settings: {
                path: '/calendar/settings'
            }
        }
    },
    documents: {
        path: '/documents',
        children: {
            fileShare: {
                path: '/documents/file-share'
            },
            agreements: {
                path: '/documents/agreements'
            },
        }
    },
    organizer: {
        path: '/organizer'
    },
    payments: {
        path: '/payments',
        children: {
            makePayment: {
                path: '/payments/make-payment'
            }
        }
    },
    questionnaire: {
        path: '/questionnaire'
    }
};
