import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClient } from '@angular/common/http';
import { GpsSettingTypesEnum } from 'src/app/shared/enums/gps-setting-types.enum';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';

@Injectable({providedIn: 'root'})
export class GpsService {
    constructor(
        private http: HttpClient,
        private geolocation: Geolocation) {
    }

    listUserGpsLocations(startDate: Date = null, endDate: Date = null, showUniqueLocationsByDay = true): Observable<any> {
        let url = `${environment().portalApiUrl}/gps?showUniqueLocationsByDay=${showUniqueLocationsByDay}`;

        if (startDate && endDate) {
            url += `&startDate=${this.getDateString(startDate)}&endDate=${this.getDateString(endDate)}`;
        }

        return this.http.get<any>(url).pipe(map(data => {
            return data.map(t => {
                t.dateTime = DateUtilities.convertToUtcDate(t.dateTime);
                return t;
            });
        }));
    }

    getCurrentLocation(): Observable<any> {
        return from(this.geolocation.getCurrentPosition())
            .pipe(flatMap(geoLocationResponse => {
                const url = `${environment().portalApiUrl}/gps/latitude/${geoLocationResponse.coords.latitude}/longitude/${geoLocationResponse.coords.longitude}`;
                return this.http.get(url);
            }));
    }

    saveCurrentGpsLocation(): Observable<void> {
        return from(this.geolocation.getCurrentPosition())
            .pipe(flatMap(geoLocationResponse => {
                const url = `${environment().portalApiUrl}/gps/latitude/${geoLocationResponse.coords.latitude}/longitude/${geoLocationResponse.coords.longitude}`;
                return this.http.post<void>(url, null);
            }));
    }

    deleteAllGpsLocations(): Observable<void> {
        const url = `${environment().portalApiUrl}/gps`;

        return this.http.delete<void>(url);
    }

    getSettings(): Observable<GpsSettingTypesEnum> {
        const url = `${environment().portalApiUrl}/gps/settings`;

        return this.http.get<GpsSettingTypesEnum>(url);
    }

    saveSettings(settingValue: GpsSettingTypesEnum): Observable<void> {
        const url = `${environment().portalApiUrl}/gps/settings/${settingValue}`;

        return this.http.post<void>(url, null);
    }

    private getDateString(date: Date): string {
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
}
