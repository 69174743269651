import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { tap } from 'rxjs/operators';
import { routes } from 'src/app/app-routes';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class TaxPayerGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!environment().disableUserTypeBlock && this.authService.getAuthToken() && this.authService.getUserId()) {
            return this.userService.isTaxPayer(this.authService.getUserId()).pipe(tap((isTaxPayer: boolean) => {
                if (!isTaxPayer) {
                    return this.router.navigate([routes.unsupportedUser.path]).then(_ => {
                        return false;
                    });
                }

                return isTaxPayer;
            }));
        }

        return of(true);
    }
}
