import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { routes } from 'src/app/app-routes';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.getAuthToken() || !this.authService.user) {
            this.authService.logout();
            return this.router.navigate([routes.login.path]).then(_ => {
                return false;
            });
        } else if (!this.authService.user.isAULASigned && state.url !== routes.documents.children.agreements.path) {
            return this.router.navigate([routes.documents.children.agreements.path]).then(_ => {
                return false;
            });
        }

        return of(true);
    }
}
