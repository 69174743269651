import * as moment from 'moment';

export class DateUtilities {
    static dateLongFormat = 'dd MMM y HH:mm:ss';
    static dateLongFormatWithoutSec = 'dd MMM y HH:mm';
    static dateShortNoYearFormat = 'dd MMM';
    static dateShortFormat = 'dd MMM yyyy';
    static dateMonthYearFormat = 'MMM yyyy';

    static convertToUtcDate(date: Date | string): Date {
        if (date && moment(date).isValid) {
            const utcDate = moment.utc(date).toDate();
            return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
        }

        return null;
    }

    static convertToUtcDateIonControl(date: Date | string): string {
        return DateUtilities.convertToUtcDate(date).toISOString();
    }

    static getFormattedDate(date: Date): string {
        if (date) {
            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;

            return month + '/' + day + '/' + year;
        } else {
            return null;
        }
    }

    static getMonthDateRange(date: Date): { startDate: Date, endDate: Date } {
        if (!date) {
            return {startDate: null, endDate: null};
        }

        const startDate = DateUtilities.convertToUtcDate(new Date(date.getFullYear(), date.getMonth(), 1));
        const endDate = new Date(startDate.getTime());
        endDate.setMonth(startDate.getMonth() + 1);
        endDate.setDate(endDate.getDate() - 1);

        return {startDate, endDate};
    }

    static getMonthOnSameDay(currentDate: Date, newDate: Date, withoutTime = true): Date {
        if (!currentDate || !newDate) {
            return currentDate;
        }

        const currentDateCopy = new Date(currentDate.getTime());
        const newDateMonth = newDate.getMonth();

        currentDateCopy.setMonth(newDate.getMonth());

        if (currentDateCopy.getMonth() !== newDateMonth) {
            while (currentDateCopy.getMonth() !== newDateMonth) {
                currentDateCopy.setDate(currentDateCopy.getDate() - 1);
            }
        }

        if (withoutTime) {
            return DateUtilities.convertToUtcDate(currentDateCopy);
        }

        return currentDateCopy;
    }

    static getDiffFromNow(date: Date, format: moment.unitOfTime.Diff) {
        return moment().diff(date, format);
    }

    static getMinMaxDateFromArray(dates: Date[]): { min: Date, max: Date } {
        if (dates) {
            dates = dates.filter(t => t);

            return {
                min: DateUtilities.convertToUtcDate(new Date(Math.min.apply(null, dates))),
                max: DateUtilities.convertToUtcDate(new Date(Math.max.apply(null, dates)))
            };
        }

        return null;
    }

    static addDays(date: Date, days: number): Date {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    }

    static getAllDatesBetweenDates(startDate: Date, endDate: Date, toIsoString: boolean = false): Date[] | string[] {
        if (startDate && endDate) {
            const dateArray = [];
            const newEndDate = DateUtilities.convertToUtcDate(endDate);
            let currentDate = DateUtilities.convertToUtcDate(startDate);
            while (currentDate <= newEndDate) {
                const dateToAdd = DateUtilities.convertToUtcDate(new Date(currentDate));
                dateArray.push(toIsoString ? dateToAdd.toISOString() : dateToAdd);
                currentDate = DateUtilities.addDays(currentDate, 1);
            }
            return dateArray;
        }

        return null;
    }
}
