import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ExploreContainerComponent } from 'src/app/shared/components/explore-container/explore-container.component';
import { GtnDatePipe } from 'src/app/shared/pipes/gtn-date.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { NotificationDatePipe } from './pipes/notification-date.pipe';
import { PasswordCardComponent } from 'src/app/shared/components/password-card/password-card.component';
import { UserPasswordInfoComponent } from 'src/app/shared/components/password-card/user-password-info/user-password-info.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule
    ],
    declarations: [
        ExploreContainerComponent,
        GtnDatePipe,
        NotificationDatePipe,
        PasswordCardComponent,
        UserPasswordInfoComponent
    ],
    exports: [
        ExploreContainerComponent,
        GtnDatePipe,
        NotificationDatePipe,
        ReactiveFormsModule,
        PasswordCardComponent
    ],
    providers: [

    ]
})
export class SharedModule {

}
