import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserPasswordInfoComponent } from 'src/app/shared/components/password-card/user-password-info/user-password-info.component';
import { first } from 'rxjs/operators';
import { FormUtilities } from 'src/app/core/utilities/form.utilties';
import { PopoverService } from 'src/app/core/services/popover.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-password-card',
    templateUrl: './password-card.component.html',
    styleUrls: ['./password-card.component.scss'],
})
export class PasswordCardComponent implements OnInit {
    @Input() isSetMode = false;
    @Input() userId: string;
    @Output() saveSuccessful: EventEmitter<void> = new EventEmitter<void>();

    passwordFormGroup: FormGroup;
    isSavingPassword = false;
    password: string;
    oldPassword: string;
    confirmPassword: string;
    showOldPassword = false;
    showNewPassword = false;
    showConfirmPassword = false;

    constructor(private popoverService: PopoverService,
                private formBuilder: FormBuilder,
                private toasterService: ToasterService,
                private authService: AuthService) {
    }

    ngOnInit() {
        if (!this.userId && this.isSetMode) {
            return;
        }

        this.initPasswordForm();
    }

    onPasswordInfoClicked(): void {
        this.popoverService.show(UserPasswordInfoComponent).pipe(first()).subscribe();
    }

    onPasswordChangeSavedClicked(): void {
        if (this.passwordFormGroup.valid) {
            this.isSavingPassword = true;
            const formValue = this.passwordFormGroup.value;

            if (this.isSetMode) {
                this.authService.setInitialPassword(this.userId, formValue.password, formValue.confirmPassword).subscribe(_ => {
                    this.onSuccess();
                }, (err => {
                    this.onFailure(err);
                }));
            } else {
                this.authService.changePassword(formValue.oldPassword, formValue.password, formValue.confirmPassword).subscribe(_ => {
                    this.onSuccess();
                }, (err => {
                    this.onFailure(err);
                }));
            }
        } else {
            this.toasterService.showError(FormUtilities.getFormErrorMessagesHtml(this.passwordFormGroup));
        }
    }

    private onSuccess(): void {
        this.toasterService.showSuccess(`Password ${this.isSetMode ? 'set' : 'updated'} successfully.`);
        this.passwordFormGroup.reset({});
        this.isSavingPassword = false;
        this.saveSuccessful.emit();
    }

    private onFailure(err: any): void {
        if (err && err.length && err.length) {
            this.toasterService.showError(err[0].message);
            this.isSavingPassword = false;
        }
    }

    private initPasswordForm(): void {
        this.passwordFormGroup = this.formBuilder.group({
            oldPassword: new FormControl(null, !this.isSetMode ? [FormUtilities.getRequiredValidator('Old password is required.')] : null),
            password: new FormControl(null, [FormUtilities.getRequiredValidator('New password is required.'), this.isInvalidPasswordValidator]),
            confirmPassword: new FormControl(null, [FormUtilities.getRequiredValidator('Confirmation password is required.'), this.isInvalidPasswordValidator])
        });

        this.passwordFormGroup.setValidators(this.areSameValidator);
    }

    private isInvalidPasswordValidator(control: AbstractControl): any {
        const validationMessage = 'Stronger password required.';
        if (!control || !control.value) {
            return null;
        }

        if (!control.value.match(/^.{8,}$/)) {
            return {invalidPassword: validationMessage};
        }

        if (control.value.match(/[^\x20-\x7E]/)) {
            return {invalidPassword: validationMessage};
        }

        if (!control.value.match(/[a-z]/)) {
            return {invalidPassword: validationMessage};
        }

        if (!control.value.match(/[A-Z]/)) {
            return {invalidPassword: validationMessage};
        }

        if (!control.value.match(/\d/)) {
            return {invalidPassword: validationMessage};
        }

        if (!control.value.match(/[!"#$%&?'()*+,-.`/<>=:;@^\\\]\[\{\}|/_~]/)) {
            return {invalidPassword: validationMessage};
        }

        return null;
    }

    private areSameValidator(formGroup: FormGroup): any {
        const password = formGroup.get('password').value;
        const confirmPasswordValue = formGroup.get('confirmPassword').value;

        if (password !== confirmPasswordValue) {
            return {passwordsDoNotMatch: 'Passwords do not match.'};
        }

        return null;
    }
}
