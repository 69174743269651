import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from 'src/app/core/core.module';
import { GlobalErrorHandlerService } from 'src/app/core/services/global-error-handler.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApplicationInitializerService } from './core/services/app-initializer.service';
import { isServiceWorkerEnabled } from 'src/app/core/utilities/service-worker.utilties';

export function init_app(appInitService: ApplicationInitializerService) {
    return () => appInitService.init();
}

@NgModule({
    declarations: [
        AppComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        CoreModule,
        IonicModule.forRoot(),
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: isServiceWorkerEnabled() }),
    ],
    exports: [
        HttpClientModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {
            provide:
            RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [
                ApplicationInitializerService,
            ],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
