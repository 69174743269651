import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class RouterService {

    private currentRoute: string;
    private previousRoute: string;

    constructor() {
    }

    getCurrentRoute(): string {
        return this.currentRoute;
    }

    getPreviousRoute(): string {
        return this.previousRoute;
    }

    setCurrentRoute(route: string): void {
        this.previousRoute = this.currentRoute;
        this.currentRoute = route;
    }
}
