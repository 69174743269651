import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { GlobalErrorHandlerService } from 'src/app/core/services/global-error-handler.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConnectivityService } from 'src/app/core/services/connectivity.service';

@Injectable()
export class HttpInterceptorUtilities implements HttpInterceptor {
    constructor(private cookieService: CookieService,
                private globalErrorHandlerService: GlobalErrorHandlerService,
                private authService: AuthService,
                private connectivityService: ConnectivityService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.connectivityService.isConnected) {
            this.globalErrorHandlerService.handleError('No internet connectivity.');
            return EMPTY;
        }

        let headers: HttpHeaders = request.headers ? request.headers : new HttpHeaders();
        headers = headers.append('Accept', 'q=0.8;application/json;q=0.9');

        const token = this.authService.getAuthToken();
        if (token) {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }

        request = request.clone({
            headers,
            url: request.url
        });

        return next.handle(request).pipe(catchError(err => {
            if (401 === err.status) {
                this.globalErrorHandlerService.handleError('Your session has expired, please log in.');
                this.authService.logout();
                window.location.href = window.location.origin;
                return;
            }

            if ([403, 404, 500].indexOf(err.status) !== -1) {
                this.globalErrorHandlerService.handleError('A network problem occurred.', (err.status === 404));

                return throwError(err?.error || err || 'A network problem occurred.');
            }

            if (err.status === 422) {
                return throwError(err);
            }

            return throwError(err.error || err?.error?.message || err?.message || err.statusText);
        }));
    }
}

