import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from './app-settings.service';
import { AuthService } from './auth.service';
import { LoggingService } from 'src/app/core/services/logging.service';
import { UserService } from 'src/app/core/services/user.service';
import { EnumService } from 'src/app/core/services/enum.service';

@Injectable({providedIn: 'root'})
export class ApplicationInitializerService {
    constructor(
        private applicationSettingsService: ApplicationSettingsService,
        private authService: AuthService,
        private userService: UserService,
        private enumsService: EnumService,
        private loggingService: LoggingService
    ) {
    }

    public async init(): Promise<any> {
        try {
            await this.applicationSettingsService.init();
            await this.authService.init();
            this.loggingService.init();

            return Promise.resolve(null);
        } catch (err) {
            this.loggingService.logError(err);
        }
    }
}
