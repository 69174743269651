import { versions } from './versions';

export class Environment {
    production: boolean;
    environment: string;
    disableUserTypeBlock: false;
    version: string;
    rememberDeviceExpirationInDays: number;

    portalIdentityUrl: string;
    portalApiUrl: string;
    myGtnPortalUrl: string;

    morganStanleyCompanyId: string;
    appInsightsConfig: {
        instrumentationKey: string
    };

    constructor() {
        // tslint:disable-next-line:no-string-literal
        const appSettings = window['appSettings'];

        if (appSettings) {
            Object.assign(this, JSON.parse(JSON.stringify(appSettings)));
        } else {
            Object.assign(this);
        }

        this.version = versions.version + (!appSettings.production ? ' (' + appSettings.environment + ')' : '');
    }
}

export const environment = () => new Environment();
