import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { from, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ConfirmDialogService {
    constructor(private alertController: AlertController) {

    }

    show(header: string = null, message: string = null, confirmText: string = null, cancelText: string = null, hideCancel: boolean = false): Observable<boolean> {
        const response = new Subject<boolean>();

        const buttons = [];

        if (!hideCancel) {
            buttons.push({
                text: cancelText || 'No',
                handler: () => {
                    response.next(false);
                }
            });
        }

        buttons.push({
            text: confirmText || 'Yes',
            handler: () => {
                response.next(true);
            }
        });

        from(this.alertController.create({
            header: header || 'Action Required',
            // subHeader: 'Subtitle',
            message: message || 'Are you sure you want to proceed?',
            backdropDismiss: false,
            buttons
        })).pipe(first()).subscribe(alert => {
            alert.present();
        });

        return response;
    }
}

