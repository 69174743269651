import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';

@Pipe({ name: 'gtnDate' })
export class GtnDatePipe extends DatePipe implements PipeTransform {
    // @ts-ignore
    transform(value: any, format: string = DateUtilities.dateShortFormat, timezone?: string, locale?: string): string | null {
        if (format && (format === DateUtilities.dateLongFormat || format === DateUtilities.dateLongFormatWithoutSec)) {
            return !!value ? super.transform(value, format, '+0000', locale) + ' GMT (00:00)' : '';
        }
        else {
            return super.transform(value, format, timezone, locale)?.toUpperCase();
        }
    }
}
