import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { EnumService } from 'src/app/core/services/enum.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnumResolver implements Resolve<any> {
    constructor(private enumService: EnumService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        return this.enumService.init();
    }
}
