import { Injectable, NgZone } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class KeyboardService {
    keyboardVisibilityChanged: Subject<boolean> = new Subject<boolean>();

    constructor(
        private keyboard: Keyboard,
        private ngZone: NgZone) {

        this.keyboard.onKeyboardWillShow().subscribe(_ => {
            this.ngZone.run(() => {
                this.keyboardVisibilityChanged.next(true);
            });
        });

        this.keyboard.onKeyboardWillHide().subscribe(_ => {
            this.ngZone.run(() => {
                this.keyboardVisibilityChanged.next(false);
            });
        });
    }

    show(): void {
        this.keyboard.show();
    }

    hide(): void {
        this.keyboard.hide();
    }
}
