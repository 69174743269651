import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserService {
    private isTaxPayerValue: boolean = null;
    private isTaxPayerUserValue: string = null;

    constructor(private http: HttpClient) {

    }

    getUserInfo(userId: string): Observable<any> {
        const url = `${environment().portalApiUrl}/user/getInfo?userId=${userId}`;
        return this.http.get(url);
    }

    getEmployments(userId: string): Observable<any> {
        const url = `${environment().portalApiUrl}/user/getUserEmployments?userId=${userId}`;
        return this.http.get(url);
    }

    isTaxPayer(userId: string): Observable<boolean> {
        if (this.isTaxPayerUserValue !== userId) {
            const url = `${environment().portalApiUrl}/user/isTaxPayer?userId=${userId}`;

            return this.http.get<any>(url).pipe(tap(d => {
                this.isTaxPayerValue = d.isTaxPayer;
                this.isTaxPayerUserValue = userId;
            }), map(d => {
                return this.isTaxPayerValue;
            }));
        }

        return of(this.isTaxPayerValue);
    }
}
