import { NgModule } from '@angular/core';
import { NavigationEnd, PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { LoginPage } from 'src/app/core/components/login-page/login.page';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { RouterService } from './core/services/router.service';
import { filter } from 'rxjs/operators';
import { routes } from 'src/app/app-routes';
import { RouteUtilities } from 'src/app/core/utilities/route.utilities';
import { EnumResolver } from 'src/app/core/resolvers/enum.resolver';
import { RegistrationPage } from 'src/app/core/components/registration-page/registration.page';
import { UnsupportedUserPage } from 'src/app/core/components/unsupported-user-page/unsupported-user-page.component';
import { TaxPayerGuard } from 'src/app/core/guards/taxpayer.guard';

const routeConfiguration: Routes = [
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.login.path),
        component: LoginPage
    },
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.registration.path),
        component: RegistrationPage
    },
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.unsupportedUser.path),
        component: UnsupportedUserPage
    },
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.dashboard.path),
        canActivate: [
            AuthGuard,
            TaxPayerGuard
        ],
        resolve: {
            enums: EnumResolver
        },
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
    },
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.calendar.path),
        canActivate: [
            AuthGuard,
            TaxPayerGuard
        ],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./modules/travel-calendar/travel-calendar.module').then(m => m.TravelCalendarModule)
    },
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.documents.path),
        canActivate: [
            AuthGuard,
            TaxPayerGuard
        ],
        resolve: {
            enums: EnumResolver
        },
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule)
    },
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.organizer.path),
        canActivate: [
            AuthGuard,
            TaxPayerGuard
        ],
        resolve: {
            enums: EnumResolver
        },
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./modules/organizer/organizer.module').then(m => m.OrganizerModule)
    },
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.payments.path),
        canActivate: [
            AuthGuard,
            TaxPayerGuard
        ],
        resolve: {
            enums: EnumResolver
        },
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('src/app/modules/payments/payments.module').then(m => m.PaymentsModule)
    },
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.user.path),
        canActivate: [
            AuthGuard,
            TaxPayerGuard
        ],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
    },
    {
        path: RouteUtilities.convertRouteToRoutingModulePath(routes.questionnaire.path),
        canActivate: [
            AuthGuard,
            TaxPayerGuard
        ],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./modules/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule)
    },
    {
        path: '**',
        redirectTo: RouteUtilities.convertRouteToRoutingModulePath(routes.login.path)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routeConfiguration, {
    preloadingStrategy: PreloadAllModules,
    useHash: true,
    enableTracing: false,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(
        private router: Router,
        private routerService: RouterService
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.routerService.setCurrentRoute(event.url);
        });
    }
}
