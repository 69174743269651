import { Injectable } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { from, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ModalService {
    constructor(private modalController: ModalController) {

    }

    show(component: any, componentProps: any = null): Observable<void> {
        return from(this.modalController.create({
            component,
            componentProps
        }).then(instance => {
            instance.present();
        }));
    }

    hide(data: any = null): Observable<any> {
        return from(this.modalController.dismiss(data));
    }
}
