import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/core/services/app.service';
import { Router } from '@angular/router';
import { routes } from 'src/app/app-routes';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-unsupported-user-page',
    templateUrl: './unsupported-user-page.component.html'
})
export class UnsupportedUserPage implements OnInit {
    myGtnPortalUrl: string = null;

    constructor(private appService: AppService,
                private router: Router,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.myGtnPortalUrl = environment().myGtnPortalUrl;
    }


    ionViewWillEnter(): void {
        this.appService.hideMenu = true;
    }

    ionViewWillLeave(): void {
        this.appService.hideMenu = false;
    }

    onBackToLoginClicked(): void {
        this.authService.logout();
        this.router.navigate([routes.login.path]);
    }
}
