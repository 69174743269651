import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { LoginPage } from 'src/app/core/components/login-page/login.page';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorUtilities } from 'src/app/core/utilities/http-interceptor.utilities';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { RegistrationPage } from 'src/app/core/components/registration-page/registration.page';
import { UnsupportedUserPage } from 'src/app/core/components/unsupported-user-page/unsupported-user-page.component';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AlertsModule } from 'src/app/modules/alerts/alerts.module';

@NgModule({
    imports: [
        AlertsModule,
        AppRoutingModule,
        IonicModule,
        CommonModule,
        FormsModule,
        SharedModule
    ],
    declarations: [
        LoginPage,
        RegistrationPage,
        UnsupportedUserPage
    ],
    providers: [
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorUtilities,
            multi: true
        },
        Geolocation,
        Keyboard
    ],
    exports: [
        AlertsModule
    ]
})
export class CoreModule {
}
