import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({providedIn: 'root'})
export class LoadingOverlayService {
    private isShown = false;

    constructor(private loadingController: LoadingController) {

    }

    show(message: string = 'Please wait...'): void {
        this.loadingController.create({
            message,
            // duration: 2000
        }).then(instance => {
            instance.present();
            this.isShown = true;
        });
    }

    hide(): void {
        this.loadingController.dismiss().then().catch(_ => {});
    }
}
