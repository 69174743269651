import { Injectable } from '@angular/core';
import { fromEvent, merge, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConnectivityService {
    public isConnected = true;
    public connectivityChanged: Subject<boolean> = new Subject();

    constructor() {
        this.initConnectivityMonitoring();
    }

    private initConnectivityMonitoring() {
        if (!window || !navigator || !('onLine' in navigator)) {
            return;
        }

        merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        ).pipe(tap(() => {
            this.isConnected = navigator.onLine;
            this.connectivityChanged.next(this.isConnected);
        })).subscribe();
    }
}
