import { Component } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { routes } from 'src/app/app-routes';
import { LoadingOverlayService } from 'src/app/core/services/loading-overlay.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
    selector: 'app-registration-page',
    templateUrl: 'registration.page.html'
})
export class RegistrationPage {
    showLinkExpired = false;
    showSetPassword = false;
    userId: string = null;

    private token: string = null;

    constructor(private appService: AppService,
                private authService: AuthService,
                private userService: UserService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private toasterService: ToasterService,
                private loadingOverlayService: LoadingOverlayService) {

    }

    ionViewWillEnter(): void {
        if (this.authService.user) {
            this.toasterService.showError('You cannot register when you are already logged in.');
            this.router.navigate([routes.dashboard.path]);
        }

        this.appService.hideMenu = true;
        this.showLinkExpired = false;
        this.showSetPassword = false;

        this.activatedRoute.queryParams.pipe(first()).subscribe(queryParams => {
            this.token = queryParams.token;
            this.userId = queryParams.userId;

            if (!this.token || !this.userId) {
                this.showLinkExpired = true;
                this.showSetPassword = false;
            } else {
                this.loadingOverlayService.show();
                this.userService.isTaxPayer(this.userId).subscribe(isTaxPayer => {
                    if (!isTaxPayer) {
                        this.loadingOverlayService.hide();
                        this.router.navigate([routes.unsupportedUser.path]);
                    } else {
                        this.authService.validateToken(this.token, this.userId).subscribe(_ => {
                            setTimeout(() => {
                                this.loadingOverlayService.hide();
                                this.showSetPassword = true;
                                this.showLinkExpired = false;
                            }, 500);
                        }, () => {
                            setTimeout(_ => {
                                this.loadingOverlayService.hide();
                                this.showLinkExpired = true;
                                this.showSetPassword = false;
                            }, 500);

                        });
                    }
                });
            }
        });
    }

    ionViewWillLeave(): void {
        this.appService.hideMenu = false;
    }

    onPasswordSet(): void {
        this.router.navigate([routes.documents.children.agreements.path]);
    }

    onBackToLoginClicked(): void {
        this.authService.logout();
        this.router.navigate([routes.login.path]);
    }
}
