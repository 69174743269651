import { Component, Input } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {
    @Input() alerts: any;
    isLoaded = false;

    constructor(private modalService: ModalService) {
        // BUG IN FRAMEWORK WHERE TEXT WONT RENDER IN BUTTON WITHIN MODAL?
        setTimeout(_ => {
            this.isLoaded = true;
        }, 100);
    }

    onCloseClicked(): void {
        this.modalService.hide().pipe(first()).subscribe();
    }
}
