import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ApplicationSettingsService {
    public init(): Promise<void> {
        return from(
            fetch('environments/app-settings.json').then(function (response) {
                return response.json();
            })
        ).pipe(
            map((config) => {
                // tslint:disable-next-line:no-string-literal
                window['appSettings'] = config;
                return;
            }))
            .toPromise();
    }
}
