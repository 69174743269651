import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AlertsService } from 'src/app/modules/alerts/core/services/alerts.service';
import { AlertsModalComponent } from 'src/app/modules/alerts/shared/components/alerts-modal/alerts-modal.component';
import { AlertsComponent } from 'src/app/modules/alerts/shared/components/alerts/alerts.component';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        SharedModule,
    ],
    declarations: [
        AlertsComponent,
        AlertsModalComponent
    ],
    providers: [
        AlertsService
    ],
    exports: [
        AlertsModalComponent
    ]
})
export class AlertsModule {
}

