<ion-app>
    <ng-container *ngIf="!appService.hideMenu">
        <ion-menu side="start" menuId="first" contentId="main" class="main-menu" [swipeGesture]="false">
            <ion-header>
                <div class="logo">
                    <img src="../assets/images/mygtnportal-logo.svg" alt="My GTN Portal Logo"/>
                </div>
                <div class="signed-in-container ion-margin-bottom" *ngIf="authService.user"><em>Signed in
                    as:</em> {{authService.user.fullName}}</div>
                <div *ngIf="deferredPrompt">
                    <ion-button (click)="onAddToHomeScreenClicked()">Add to Home Screen</ion-button>
                </div>
                <div class="white-space-container" *ngIf="!deferredPrompt">

                </div>
                <ion-list>
                    <ion-item lines="none">
                        <ion-menu-toggle auto-hide="true">
                            <ng-container *ngFor="let menuItem of menuItems; let last = last">
                                <ion-item lines="none"
                                          (click)="onMenuItemClicked(menuItem)">
                                    <ion-icon color="primary" slot="start" [name]="menuItem.icon"></ion-icon>
                                    <ion-label color="primary" class="menu-item-title">{{menuItem.title}}</ion-label>
                                </ion-item>
                                <div class="version" *ngIf="last">
                                    Version {{version}}
                                </div>
                            </ng-container>
                        </ion-menu-toggle>
                    </ion-item>
                    <ion-item class="location-container" *ngIf="authService.user" lines="none"
                              (click)="navigateToUserSettings()">
                        <ion-menu-toggle auto-hide="true">
                            <ng-container *ngIf="locationData">
                                <ion-item lines="none">
                                    <ion-icon name="navigate-circle" slot="start"></ion-icon>
                                    <div class="content-container">
                                        <div>{{locationCountry}}</div>
                                        <div>{{locationCityState}}</div>
                                    </div>
                                </ion-item>
                            </ng-container>
                            <div *ngIf="isLoadingLocation">
                                <div>
                                    Determining your location . . .
                                </div>
                                <ion-progress-bar type="indeterminate"></ion-progress-bar>
                            </div>
                            <div class="ion-margin-vertical" *ngIf="!isLoadingLocation && locationData === null && !isLocationDisabled">
                                <ion-item lines="none">
                                    <ion-icon name="navigate-circle" color="medium" slot="start"></ion-icon>
                                    <ion-text class="smaller">Location services are currently disabled. Go to your <a
                                            href="javascript:void(0)" (click)="navigateToUserSettings()">User
                                        Settings</a> to enable GPS tracking.
                                    </ion-text>
                                </ion-item>
                            </div>
                            <div class="ion-margin-vertical" *ngIf="!isLoadingLocation && isLocationDisabled">
                                <ion-item lines="none">
                                    <ion-icon name="navigate-circle" color="medium" slot="start"></ion-icon>
                                    <ion-text class="smaller">Location services are currently disabled on your device. Go to your phone settings to enable GPS Tracking.
                                    </ion-text>
                                </ion-item>
                            </div>
                        </ion-menu-toggle>
                    </ion-item>
                </ion-list>
            </ion-header>
        </ion-menu>
        <ion-header class="header-container">
            <ion-toolbar color="primary">
                <div class="app-header">
                    <div>
                        <ion-menu-button></ion-menu-button>
                    </div>
                    <div>
                        <ion-title>{{appService.getTitle()}}
                            <ion-icon *ngIf="appService.iconName" [name]="appService.iconName"
                                      (click)="appService.titleIconClicked.next()" size="small"></ion-icon>
                        </ion-title>
                    </div>
                    <div>
                        <app-alerts-modal *ngIf="authService.user"></app-alerts-modal>
                    </div>
                </div>
            </ion-toolbar>
        </ion-header>
    </ng-container>
    <ion-router-outlet id="main" [swipeGesture]="false"></ion-router-outlet>
</ion-app>
