import { Subscription } from 'rxjs';

export class BasePage {
    subscriptions: Subscription[] = [];

    constructor() {
    }

    ionViewWillLeave(): void {
        this.subscriptions.forEach(s => {
            if (s) {
                s.unsubscribe();
            }
        });
    }
}
