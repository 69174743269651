import { Pipe, PipeTransform } from '@angular/core';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';

@Pipe({ name: 'notificationDate' })
export class NotificationDatePipe implements PipeTransform {
    transform(value: any, format: string = DateUtilities.dateShortFormat, timezone?: string, locale?: string): string {
        const timeAgo = DateUtilities.getDiffFromNow(value, 'minutes');

        // years
        if (timeAgo >= (60 * 24 * 52 * 365)) {
            return DateUtilities.getDiffFromNow(value, 'years').toString() + 'y';
        }

        // skipping months, because that's just crazy

        // weeks
        else if (timeAgo >= (60 * 24 * 7)) {
            return DateUtilities.getDiffFromNow(value, 'weeks').toString() + 'w';
        }

        // days
        else if (timeAgo >= (60 * 24)) {
            return DateUtilities.getDiffFromNow(value, 'days').toString() + 'd';
        }

        // hours
        if (timeAgo >= 60) {
            return DateUtilities.getDiffFromNow(value, 'hours').toString() + 'h';
        }

        else {
            return timeAgo.toString() + 'm';
        }
    }
}
