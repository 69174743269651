<ion-content>
    <div class="main-content no-menu-offset" [ngClass]="{'vertical-center-items': showLinkExpired}">
        <p class="header ion-text-center">
            <img src="../../../assets/images/mygtnportal-logo.svg" alt="MyGTNPortal logo" width="175"/>
        </p>
        <ng-container *ngIf="showLinkExpired">
            <ion-item class="ion-margin-vertical">
                <ion-text class="ion-text-center">
                    This link is no longer valid.<br/><br/>
                    For further assistance, contact <a href="mailto:help@mygtnportal.com">help@mygtnportal.com</a> or
                    your company administrator.<br/><br/>
                    <a href="javascript:void(0)" (click)="onBackToLoginClicked()">Back to Login</a>
                </ion-text>
            </ion-item>
        </ng-container>
        <ng-container *ngIf="showSetPassword">
            <app-password-card [isSetMode]="true" [userId]="userId" (saveSuccessful)="onPasswordSet()"></app-password-card>
        </ng-container>
    </div>
</ion-content>
