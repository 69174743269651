import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/modules/alerts/core/services/alerts.service';
import { from } from 'rxjs';
import { AlertsComponent } from 'src/app/modules/alerts/shared/components/alerts/alerts.component';
import { ModalService } from 'src/app/core/services/modal.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-alerts-modal',
    templateUrl: './alerts-modal.component.html',
    styleUrls: ['./alerts-modal.component.scss'],
})
export class AlertsModalComponent implements OnInit {
    alerts: any;
    private isModalOpen = false;
    private maxAlerts = 5;

    constructor(private modalService: ModalService,
                private alertService: AlertsService) {
    }

    ngOnInit() {
        this.alertService.list().subscribe(alerts => {
            if (alerts?.models) {
                this.alerts = alerts.models.slice(0, this.maxAlerts);
            }
        });
    }

    onAlertsClicked(): void {
        if (this.alerts?.length) {
            this.isModalOpen = true
            this.modalService.show(AlertsComponent, {
                alerts: this.alerts
            }).pipe(first()).subscribe();
        }
    }
}
