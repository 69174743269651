import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GpsSettingTypesEnum } from 'src/app/shared/enums/gps-setting-types.enum';
import { ToasterService } from 'src/app/core/services/toaster.service';

@Injectable({providedIn: 'root'})
export class AppService {
    hideMenu = false;
    gpsSettingChanged: Subject<GpsSettingTypesEnum> = new Subject();
    questionnaireCompleted: Subject<void> = new Subject();
    titleIconClicked: Subject<void> = new Subject();

    title: string;
    iconName: string;

    constructor(private toasterService: ToasterService) {

    }

    getTitle(): string {
        return this.title;
    }

    setTitle(title: string, iconName: string = null): void {
        this.title = title;
        this.iconName = iconName;
    }

    openNewWindow(url: string): any {
        const newWin = window.open(url, '_blank');

        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
            this.toasterService.showError('Please disable pop-up blocker and try again.');
            return null;
        }

        return newWin;
    }
}
