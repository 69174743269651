import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class EnumService {
    private enums: any;

    constructor(private http: HttpClient) {

    }

    init(): Observable<void> {
        const url = `${environment().portalApiUrl}/values/GetExportableEnums`;

        if (this.enums) {
            return of(this.enums);
        }

        return this.http.get<any>(url).pipe(tap(data => {
            this.enums = data;
        }));
    }

    getEnumItems(enumName: string): { label: string, id: string }[] {
        if (enumName) {
            return this.getEnumValue(enumName);
        }

        return null;
    }

    private getEnumValue(enumName: string): { label: string, id: string }[] {
        const result: { label: string, id: string }[] = [];
        for (const i in this.enums[enumName]) {
            if (this.enums[enumName][i]) {
                result.push({
                    label: this.enums[enumName][i].item2,
                    id: this.enums[enumName][i].item1
                });
            }
        }

        return result;
    }
}
