import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from 'src/app/core/services/user.service';
import { Router } from '@angular/router';
import { routes } from 'src/app/app-routes';

@Injectable({providedIn: 'root'})
export class AuthService {
    user: any;
    userChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    isLoggingIn: BehaviorSubject<boolean> = new BehaviorSubject(null);
    loginFailed: BehaviorSubject<any> = new BehaviorSubject(null);

    private portalIdentityUrl: string;
    private portalApiUrl: string;
    private deviceKey = 'deviceId';
    private userIdKey = 'userId';
    private tokenKey = 'access_token';

    constructor(private httpClient: HttpClient,
                private userService: UserService,
                private cookieService: CookieService,
                private router: Router) {
    }

    async init(): Promise<{ userId: string, requires2fa: boolean, showRememberMe: boolean, maskedEmail: string }> {
        this.portalApiUrl = environment().portalApiUrl;
        this.portalIdentityUrl = environment().portalIdentityUrl;

        // REFRESH SCENARIO TO ENSURE USER CONTEXT IS LOADED
        if (this.getUserId() && !this.user) {
            const userPromise = this.userService.getUserInfo(this.getUserId()).toPromise();
            const user = await userPromise;
            this.userChanged.next(user);
            this.user = user;
            return Promise.resolve(null);
        }

        return Promise.resolve(null);
    }

    login(userName: string, userPassword: string, rememberMe: boolean, tfaCode: number = null):
        Observable<{ userId: string, requires2fa: boolean, showRememberMe: boolean, maskedEmail: string }> {

        this.isLoggingIn.next(true);
        const model = {
            email: userName,
            password: userPassword,
            maskedEmail: null,
            deviceId: this.getDeviceId(),
            rememberMe,
            tfaCode
        };

        return this.httpClient.post(`${this.portalIdentityUrl}/identityUser/Login`, model).pipe(
            tap((data: {
                token: { access_token: string },
                userId: string,
                tfaCodeRequired: boolean,
                deviceId: string,
                maskedEmail: string,
                tfaShowRememberThisDevice: boolean
            }) => {
                if (data) {
                    if (data.token && data.userId) {
                        this.setAuthToken(data.token.access_token);
                        this.setUserId(data.userId);
                    }

                    if (data.deviceId && rememberMe) {
                        this.setDeviceId(data.deviceId);
                    } else {
                        this.setDeviceId(null);
                    }
                } else {
                    throwError(new Error('An error occurred during authentication'));
                }
            }),
            map(data => {
                return {
                    userId: data.userId,
                    maskedEmail: null,
                    requires2fa: data.tfaCodeRequired,
                    showRememberMe: data.tfaShowRememberThisDevice
                };
            }), flatMap(data => {
                if (data.requires2fa) {
                    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
                    return this.httpClient.get(`${this.portalApiUrl}/user/getMaskedEmail?id=${data.userId}`, {
                        headers,
                        responseType: 'text' as 'json'
                    }).pipe(map(email => {
                        data.maskedEmail = email;
                        return data;
                    }));
                } else {
                    return this.userService.getUserInfo(data.userId).pipe(map(userInfo => {
                        this.userChanged.next(userInfo);
                        this.user = userInfo;
                        return data;
                    }));
                }
            }));
    }

    logout(): void {
        this.cookieService.delete(this.userIdKey);
        this.cookieService.delete(this.tokenKey);
        this.userChanged.next(null);
        this.user = null;
    }

    sendForgotPasswordLink(userID: string): Observable<{ result: boolean }> {
        return this.httpClient.get(`${this.portalIdentityUrl}/IdentityUser/validateUserId?UserName=${encodeURIComponent(userID)}&captcha=`)
            .pipe(flatMap((t: { identityUserId: string }) => {
                if (t && t.identityUserId) {
                    return this.httpClient.get<{ result: boolean }>(`${this.portalApiUrl}/user/sendForgotPasswordEmail?identityUserId=${t.identityUserId}`);
                }

                return of({result: false});
            }));
    }

    sendForgotUserIDLink(email: string): Observable<{ result: boolean }> {
        return this.httpClient.get(`${this.portalIdentityUrl}/IdentityUser/validateUserId?UserName=${encodeURIComponent(email)}&captcha=`)
            .pipe(flatMap((t: { identityUserId: string }) => {
                if (t && t.identityUserId) {
                    return this.httpClient.get<{ result: boolean }>(`${this.portalApiUrl}/user/sendForgotUserIdEmail?identityUserId=${t.identityUserId}`).pipe(map(_ => {
                        return {result: true};
                    }));
                }

                return of({result: false});
            }));
    }

    changePassword(oldPassword: string, password: string, confirmPassword: string): Observable<any> {
        const model = {
            confirmPassword,
            password,
            oldPassword
        };

        return this.httpClient.post(`${this.portalIdentityUrl}/IdentityUser/ChangePassword`, model).pipe(tap(data => {
            if (data.token && data.userId) {
                this.setAuthToken(data.token.access_token);
                this.setUserId(data.userId);
            }
        }));
    }

    validateToken(token: string, userId: string): Observable<void> {
        return this.httpClient.post<any>(`${this.portalIdentityUrl}/Token/VerifyConfirmationToken`, {
            id: userId,
            token
        });
    }

    setInitialPassword(userId: string, password: string, confirmPassword: string): Observable<void> {
        return this.httpClient.post<any>(`${this.portalIdentityUrl}/identityUser/setSecurityInfo`, {
            id: userId,
            userPassword: {
                password,
                confirmPassword
            },
            securityAnswers: []
        }).pipe(flatMap((data: {token: {access_token: string}, userId: string}) => {
            this.setAuthToken(data.token.access_token);
            this.setUserId(data.userId);

            return this.userService.getUserInfo(data.userId).pipe(map(userInfo => {
                this.userChanged.next(userInfo);
                this.user = userInfo;
            }));
        }), flatMap(_ => {
            return this.httpClient.get<void>(`${this.portalApiUrl}/user/completeRegistration?userId=${userId}`);
        }));
    }

    getAuthToken(): string {
        return this.cookieService.get(this.tokenKey);
    }

    getUserId(): any {
        return this.cookieService.get(this.userIdKey);
    }

    getDeviceId(): string {
        return this.cookieService.get(this.deviceKey);
    }

    private setDeviceId(deviceId: string): void {
        this.cookieService.set(this.deviceKey, deviceId, environment().rememberDeviceExpirationInDays);
    }

    private setUserId(userId: string): any {
        return this.cookieService.set(this.userIdKey, userId);
    }

    private setAuthToken(token: string): void {
        this.cookieService.set(this.tokenKey, token, 1);
    }
}
